/* nav */
nav {
  position: fixed;
  top: 0;
  width: 100%;
  height: 70px;
  padding: 1.5em 1em;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  z-index: 10;
}

.logo,
.clock,
.ctas {
  flex: 1;
}

.logo img {
  width: 36px;
}

.ctas {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  gap: 0.5em;
}

.sound,
.contact,
.menu {
  flex: 1;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--color-text);
  border-radius: 8px;
  cursor: pointer;
  padding: 1em 0;
}

.sound p,
.contact a,
.menu p {
  color: var(--color-bg);
}

.menu,
.contact {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.menu p,
.contact a {
  padding-right: 0.75em;
}

.menu .menu-icon,
.contact .contact-icon {
  position: relative;
  left: 0.125em;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotate(45deg);
  transition: transform 1s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.contact .contact-icon {
  color: var(--color-text);
  transform: rotate(0);
  left: 0.25em;
  font-size: 8px;
}

.contact {
  background: #ff6b00;
  background: linear-gradient(to right, #fc002d, #ff6b00);
}

.contact a {
  color: var(--color-text);
  position: absolute;
  right: 0;
  width: 100%;
  text-align: right;
}

.menu.active {
  background: var(--color-text);
}

.menu.active p,
.menu.active ion-icon {
  color: var(--color-bg);
}

.menu.active .menu-icon {
  transform: rotate(270deg);
  transition: transform 1s cubic-bezier(0.165, 0.84, 0.44, 1);
}

@media (max-width: 900px) {
  .ctas {
    position: fixed;
    left: 0;
    bottom: 1em;
    width: 100%;
    height: 60px;
    padding: 1em;
  }

  .clock {
    text-align: right;
  }

  .menu,
  .contact {
    padding: 1.25em 0.5em;
  }

  .menu .menu-icon {
    left: 0;
  }

  .menu.active {
    background: var(--color-bg);
  }

  .menu.active p,
  .menu.active ion-icon {
    color: var(--color-text);
  }
}
