.promo {
  position: relative;
  width: 100%;
  height: 100vh;
  padding: 1.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

}

.promo-bg {
  position: absolute;
  top: -25%;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.promo-header {
  position: relative;
  z-index: 1;
}

.promo h1,
.promo h2 {
  color: var(--color-text);
  font-family: var(--font-secondary);
  text-transform: uppercase;
  text-align: center;
  line-height: 100%;
  margin: 0;
}

.promo h2 {
  font-size: 3vw;
  letter-spacing: 0;
  line-height: 105%;
}

.promo h1 {
  margin: 0;
  font-size: 10vw;
}

@media (max-width: 900px) {
  .promo {
    height: 70vh;
  }

  .promo h2 {
    font-size: 4vw;
  }

  .promo h1 {
    font-size: 12vw;
  }
}
