/* Overall Product Section */
.product-section {
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  padding: 2em;
  background-color: #000;
  align-items: center;
  margin-top: -200px;
}

/* Individual Product Card */
.product-card {
  margin-top: 18px;
  display: flex;
  flex-direction: row;
  gap: 1.5em;
  background-color: #1a1b23;
  border-radius: 16px;
  overflow: hidden;
  max-width: 800px;
  padding: 1.5em;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;

}

.product-card:hover {
  transform: scale(1.03) rotateX(3deg) rotateY(3deg);
  border: 1px solid rgb(255, 112, 112);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

/* Product Content */
.product-content {
  flex: 1;
  color: #eaeaea;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.product-content h3 {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 0.5em;
  color: #fff;
}

.product-content p {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 1em;
  color: #ffffff;
}

/* CTA Buttons */
.product-cta {
  display: flex;
  gap: 1em;
  justify-content: left;
  font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.cta-button {
  padding: 0.6em 1.2em;
  margin-top: 40px;
  font-size: 0.9rem;
  font-weight: bold;
  font-family:Arial, Helvetica, sans-serif;
  color: #eaeaea;
  background-color: #333;
  border: 1px solid #444;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.cta-button:hover {
  background-color: #514444;
  border-color: #555;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  transform: translateY(-2px);
}

/* Image Container */
.product-image {
  flex: 1;
  max-width: 300px;
  overflow: hidden;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.product-image img {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 12px;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .product-card {
    flex-direction: column;
    text-align: center;
  }

  .product-image {
    max-width: 100%;
  }

  .product-content {
    text-align: center;
  }
}
