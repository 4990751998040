:root {
  --font-primary: "PP Object Sans";
  --font-secondary: "Druk Wide Trial";

  --color-text: #fff;
  --color-text-secondary: #eaeaea;

  --color-bg: #000;
  --color-bg-secondary: #131416;

  --color-callout: #24262a;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  width: 100%;
  height: 100%;
  font-family: var(--font-primary);
  background-color: var(--color-bg);
}

html.lenis,
html.lenis body {
  height: auto;
}

.lenis.lenis-smooth {
  scroll-behavior: auto !important;
}

.lenis.lenis-smooth [data-lenis-prevent] {
  overscroll-behavior: contain;
}

.lenis.lenis-stopped {
  overflow: hidden;
}

.lenis.lenis-smooth iframe {
  pointer-events: none;
}

a,
p,
span {
  text-decoration: none;
  font-size: 13px;
  color: var(--color-text);
}

h2 {
  font-weight: 500;
  font-size: 80px;
  color: var(--color-text-secondary);
  letter-spacing: -2px;
  line-height: 100%;
}

img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.container {
  position: relative;
  width: 100%;
  height: 100%;
  background: var(--color-bg);
}

.callout {
  width: max-content;
  padding: 0.5em 1em;
  text-transform: uppercase;
  background-color: rgba(255, 255, 255, 0.125);
  border-radius: 12px;
  margin-bottom: 2em;
}
