.footer {
  background-color: #101010;
  color: #ffffff;
  padding: 40px 20px;
  font-family: Arial, sans-serif;
  text-align: left;
}

.gradient-divider {
  height: 4px;
  background: linear-gradient(90deg, #4a90e2, #50c878, #4a90e2);
  margin-bottom: 30px;
  border: none;
}

.footer-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
}

.company-info {
  flex: 1.5;
  min-width: 250px;
}

.company-info h2 {
  font-size: 24px;
  margin-bottom: 10px;
  color: #ffffff;
}

.company-info p {
  font-size: 14px;
  color: #cccccc;
  margin: 0;
  line-height: 1.6;
}

.footer-column {
  flex: 1;
  min-width: 200px;
}

.footer-column h3 {
  font-size: 18px;
  margin-bottom: 15px;
  color: #ffffff;
  font-weight: bold;
  border-bottom: 2px solid #50c878;
  display: inline-block;
  padding-bottom: 5px;
}

.footer-column ul {
  list-style: none;
  padding: 0;
}

.footer-column ul li {
  margin-bottom: 10px;
}

.footer-column ul li a {
  color: #cccccc;
  text-decoration: none;
  font-size: 14px;
  transition: color 0.3s;
}

.footer-column ul li a:hover {
  color: #ffffff;
}

.social-icons {
  display: flex;
  gap: 15px;
}

.social-icons a {
  color: #cccccc;
  font-size: 20px;
  transition: color 0.3s, transform 0.3s;
}

.social-icons a:hover {
  color: #50c878;
  transform: scale(1.1);
}

.footer-bottom {
  margin-top: 40px;
  font-size: 14px;
  color: #777777;
  text-align: center;
  border-top: 1px solid #333333;
  padding-top: 20px;
}

@media (max-width: 768px) {
  .footer-container {
    flex-direction: column;
    gap: 30px;
  }

  .company-info {
    text-align: center;
  }

  .footer-column {
    text-align: center;
  }
}
