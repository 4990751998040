/* menu-container */
.menu-container {
  position: fixed;
  top: 46px;
  left: 0;
  width: 100%;
  padding: 1em;
  display: flex;
  text-transform: uppercase;
  z-index: 1;
  opacity: 0;
  pointer-events: none;
}

.whitespace {
  pointer-events: none;
}

.whitespace,
.menu-items {
  flex: 1;
  padding: 0.25em;
}

.menu-items {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0.25em;
  background: var(--color-text);
  border-radius: 8px;
  overflow: hidden;
  pointer-events: none;
  z-index: 0;
}

.menu-item {
  position: relative;
  width: 100%;
  padding: 1em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--color-text-secondary);
  border-radius: 5px;
}

.menu-item span {
  color: var(--color-bg);
}

@media (max-width: 900px) {
  .menu-container {
    top: unset;
    bottom: calc(0px);
    padding: 0;
  }

  .menu-items {
    border-radius: 8px 8px 0px 0px;
    padding-bottom: 100px;
  }

  .whitespace {
    display: none;
  }
}
