/* Overall Contact Page */
.contact-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2em;
  background-color: #0d0e12;
  color: #eaeaea;
  min-height: 100vh;
}

.contact-header {
  text-align: center;
  margin-bottom: 2em;
}

.contact-header h1 {
  font-size: 2.5rem;
  margin-bottom: 0.5em;
}

.contact-header p {
  font-size: 1.1rem;
  color: #ccc;
  max-width: 600px;
}

/* Contact Form */
.contact-form {
  width: 100%;
  max-width: 600px;
  background-color: #ffffff;
  padding: 2em;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.form-row {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5em;
}

.form-row input,
.form-row textarea {
  width: 100%;
  padding: 1em;
  margin-bottom: 0.5em;
  border: none;
  border-radius: 6px;
  background: #333;
  color: #eaeaea;
  font-size: 1rem;
  outline: none;
  transition: border 0.3s ease;
}

.form-row input:focus,
.form-row textarea:focus {
  border: 1px solid #ff6b00;
}

/* Submit Button */
.submit-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.8em 1.5em;
  background: linear-gradient(to right, #fc002d, #ff6b00);
  color: #000000;
  border: none;
  border-radius: 8px;
  font-size: 1.1rem;
  font-weight: bold;
  cursor: pointer;
  transition: background 0.3s ease;
}

.submit-btn IonIcon {
  font-size: 1.2rem;
  margin-right: 0.5em;
}

.submit-btn:hover {
  background: #ff6b00;
}

@media (max-width: 768px) {
  .form-row {
    flex-direction: column;
  }

  .contact-header h1 {
    font-size: 2rem;
  }

  .submit-btn {
    font-size: 1rem;
    padding: 0.6em 1em;
  }
}
